:root {
	--body: #f6f6f6;
	--secondary-title: #4c4c6d;
	--s2: #1b2430;
	--grey: #9d9d9d;
	--lightgray: lightgray;
	--grey-2: #5e5e5f;
	--white: #fff;
	--black: #000;
	--error: red;
	--b-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.04);
	--hover-b-shadow: 0 8px 24px #969ea633;
	--overlay-bg: #00000080;

	--outline: rgba(0, 0, 0, 0.07);
}

[data-theme='dark'] {
	--body: #010409;
	--secondary-title: #aeaeae;
	--s2: rgb(170, 170, 170);
	--grey: #9d9d9d;
	--lightgray: lightgray;
	--grey-2: #5e5e5f;
	--white: #0d1117;
	--black: rgb(228, 228, 228);
	--error: red;
	--b-shadow: none;
	--hover-b-shadow: none;
	--overlay-bg: #17171780;

	--outline: rgba(255, 255, 255, 0.07);
}

[data-theme='dark'] [data-invertable='true'] {
	filter: invert(1);
}

[data-theme='dark'] .work_pg .work_card .proj__images img {
	filter: brightness(0.8);
}

* {
	transition: background-color 0.2s, color 0.5s, filter 1s;
}

/* 
@media (prefers-color-scheme: dark) {
	:root {
		--body: #010409;
		--secondary-title: #aeaeae;
		--s2: rgb(170, 170, 170);
		--grey: #9d9d9d;
		--lightgray: lightgray;
		--grey-2: #5e5e5f;
		--white: #0d1117;
		--black: rgb(228, 228, 228);
		--error: red;
		--b-shadow: none;
		--hover-b-shadow: none;
		--overlay-bg: #17171780;
	}

	[data-invertable='true'] {
		filter: invert(1);
	}

	.work_pg .work_card img {
		filter: brightness(0.8);
	}
} */
