@font-face {
	font-family: satoshi;
	font-weight: 300;
	src: url(fonts/Satoshi/Satoshi-Light.otf);
}
@font-face {
	font-family: satoshi;
	font-weight: 400;
	src: url(fonts/Satoshi/Satoshi-Regular.otf);
}
@font-face {
	font-family: satoshi;
	font-weight: 500;
	src: url(fonts/Satoshi/Satoshi-Medium.otf);
}
@font-face {
	font-family: satoshi;
	font-weight: 700;
	src: url(fonts/Satoshi/Satoshi-Bold.otf);
}
@font-face {
	font-family: satoshi;
	font-weight: 900;
	src: url(fonts/Satoshi/Satoshi-Black.otf);
}
* {
	box-sizing: border-box;
	font-family: satoshi;
	margin: 0;
	padding: 0;
}
body {
	background-color: var(--body);
	font-family: serif;
}
.cont {
	margin: 0 auto;
	max-width: 1920px;
	width: 94%;
}
img {
	max-width: 100%;
	user-select: none;
}
li {
	list-style: none;
}
::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	background-color: var(--grey);
}
::-webkit-scrollbar-thumb {
	background: linear-gradient(317.33deg, var(--black), transparent 115.43%);
}
a {
	text-decoration: none;
}

.swiper-3d .swiper-slide-shadow {
	background: none !important;
}

.invalid {
	border-color: var(--error) !important;
}

.width-100 {
	width: 100%;
}
.height-100 {
	height: 100%;
}
.text-center {
	text-align: center;
}
.min-h {
	min-height: 280px;
}
.link_aero {
	box-shadow: none !important;
	position: inherit;
	width: 70px !important;
}
nav {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 20px 0;
	position: relative;
}

li.theme {
	color: var(--black);
	position: fixed;
	left: 0;
	top: 0;
	transform: rotate(-50deg);
	cursor: pointer;
	z-index: 100;
}

li.theme::after {
	content: attr(data-area-label);
	position: absolute;
	top: 85px;
	left: -15%;
	transform: rotate(50deg);
	width: max-content;
	padding: 10px 20px;
	background-color: var(--white);
	color: var(--black);
	border-radius: 5px;
	transition: all 0.3s;
	opacity: 0;
	visibility: hidden;
}

li.theme:hover:after {
	opacity: 1;
	visibility: visible;
}

li.theme svg path {
	fill: var(--black);
	transition: fill 0.3s;
}

li.theme svg .spark {
	animation: theme 0.2s ease-out;
	transform-origin: center;
}
@keyframes theme {
	0% {
		transform: scale(0.2);
	}
	100% {
		transform: scale(1);
	}
}

/* fixed Navbar */

@media (min-width: 999px) {
	nav ul {
		padding: 0 40px 0;
		border-radius: 100px;
		z-index: 99;
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
		transition: padding 0.5s, background 0.15s, gap 0.3s, box-shadow 0.3s;

		will-change: padding, background, gap;
	}

	nav ul[data-sticky='true'] {
		background: var(--white);
		box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
		outline: 2px solid var(--outline);
		gap: 30px;
	}
}

nav .logo {
	width: 130px;
}
nav .menubtn {
	align-items: center;
	background-color: var(--white);
	border-radius: 50%;
	box-shadow: 0 8px 24px hsla(210, 8%, 62%, 0.2);
	display: none !important;
	display: flex;
	height: 50px;
	justify-content: center;
	padding: 12px;
	width: 50px;
}
nav .menubtn img {
	pointer-events: none;
	-webkit-user-select: none;
	user-select: none;
	width: 100%;
	width: 31px;
}
nav ul {
	align-items: center;
	display: flex;
	gap: 20px;
	user-select: none;
}

nav ul a {
	padding: 15px;
	display: block;
}

nav ul:hover a {
	opacity: 0.4;
}
nav ul .mbl {
	display: none;
}
nav ul li {
	cursor: pointer;
	font-size: 18px;
	font-weight: 500;
}
nav ul li a {
	color: var(--black);
	transition: all 0.2s;
}

nav ul li a span.extrnl {
	font-size: 14px;
}

nav ul li:hover a {
	opacity: 1;
}

nav .cnt_btn {
	background-image: linear-gradient(317.33deg, var(--black), transparent 115.43%);
	border: 0;
	border-radius: 15px;
	color: var(--white);
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	height: 45px;
	justify-self: flex-end;
	width: 180px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-image 0.5s linear;
	background-position: left;
}

nav .cnt_btn:hover {
	background-image: linear-gradient(317.33deg, var(--black), transparent 155.43%);
}

html[data-theme='dark'] nav .cnt_btn {
	opacity: 0.8;
}

.cards_pskj {
	grid-gap: 20px;
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(2, 1fr);
	padding: 50px 0;
}
.bg-w {
	background-color: var(--white);
}
.card {
	width: 100%;
}
.b-radius {
	border-radius: 20px;
}
.bx-s {
	box-shadow: var(--b-shadow);
}
.pad {
	overflow: hidden;
	padding: 40px;
	position: relative;
}

.loading-m {
	height: 100vh;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loading-m img {
	width: 50px;
	height: 50px;
	user-select: none;
	pointer-events: none;
}

.work_card {
	padding: 30px 40px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.work_card .swiper {
	width: 100%;
}

.pad .hover_link {
	align-items: center;
	-webkit-backdrop-filter: blur(15px);
	backdrop-filter: blur(15px);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 50%;
	opacity: 0;
	position: absolute;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	transition: all 0.3s;
	visibility: hidden;
	width: 100%;
}
.pad .hover_link img {
	width: 70px;
}
.pad:hover {
	box-shadow: var(--hover-b-shadow);
}
.pad:hover .hover_link {
	opacity: 1;
	visibility: visible;
}
.hover_link img {
	opacity: 0;
	transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	visibility: hidden;
}
.pad:hover .hover_link img {
	opacity: 1;
	visibility: visible;
}
.card .my_img {
	background: linear-gradient(145deg, #ededed, var(--grey-2));
	border-radius: 50%;
	box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, 0.2);
	height: 80px;
	padding: 10px;
	width: 80px;
	object-fit: cover;
}
.card h2.my_name {
	font-family: satoshi;
	font-size: 32px;
	font-weight: 900;
	margin-top: 20px;
	color: var(--black);
}
.card h3 {
	color: var(--grey);
	color: var(--secondary-title);
	font-size: 22px;
	margin-top: 20px;
}
.abt {
	grid-gap: 20px;
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(2, 1fr);
	padding: 0;
	width: 100%;
}
.abt img {
	width: 70px;
}
.abt h2 {
	color: var(--secondary-title);
}
.abt p {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 10px;
	color: var(--black);
}
.abt .flex-col {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 280px;
}
.abt .resume {
	padding: 40px 30px;
}
.abt .about img svg {
	fill: var(--error);
}
.abt .project {
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 280px;
}
.abt .project img {
	position: relative;
	width: 100%;
	width: 70px;
}
.abt .project .hover_link img {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.skill {
	padding-top: 20px;
}
.skill h2 {
	font-size: 32px;
	font-weight: 900;
	color: var(--black);
}
.skill .skills {
	align-items: baseline;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 80px;
}
.skill .skills li {
	cursor: pointer;
	position: relative;
}
.skill .skills li img {
	height: 50px;
	width: 50px;
}
.skill .skills li span {
	background-color: var(--s2);
	border-radius: 10px;
	color: var(--white);
	text-transform: uppercase;
	display: block;
	font-size: 16px;
	font-weight: 500;
	left: 50%;
	opacity: 0;
	padding: 7px 16px;
	position: absolute;
	-webkit-transform: translateX(-50%) translateY(0);
	transform: translateX(-50%) translateY(0);
	transition: all 0.3s;
	width: -webkit-max-content;
	width: max-content;
}
.skill .skills li:hover span {
	opacity: 1;
	-webkit-transform: translateX(-50%) translateY(-40px);
	transform: translateX(-50%) translateY(-40px);
}
.contact_card {
	min-height: 280px;
	position: relative;
}
.contact_card .cntt {
	bottom: 40px;
	position: absolute;
}
.contact_card h2 {
	font-size: 28px;
	color: var(--black);
}
.contact_card h3 {
	font-size: 54px;
	font-weight: 700;
}
.contact_card h3 a {
	-webkit-text-fill-color: transparent;
	background: linear-gradient(317.33deg, var(--black), transparent 115.43%);
	-webkit-background-clip: text;
}
.cortex .cntt {
	bottom: 40px;
	position: absolute;
	z-index: 95;
}
.cortex .cntt h2 {
	font-size: 28px;
	color: var(--black);
}
.cortex .cntt h3 {
	-webkit-text-fill-color: transparent;
	background: linear-gradient(317.33deg, var(--black), transparent 115.43%);
	-webkit-background-clip: text;
	font-size: 54px;
	font-weight: 700;
}
.cortex img {
	position: absolute;
	right: 30px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 150px;
}

footer .slogo {
	cursor: pointer;
	display: block;
	margin: 10px auto;
	pointer-events: none;
	-webkit-user-select: none;
	user-select: none;
	width: 40px;
}
footer .footerlinks {
	align-items: flex-end;
	display: flex;
	gap: 30px;
	justify-content: center;
	margin: 30px 0 30px;
}
footer .footerlinks li a {
	display: flex;
}
footer .footerlinks li img {
	width: 30px;
}
footer .copyright {
	color: var(--grey-2);
	font-size: 18px;
	font-weight: 700;
}
.grid2 {
	grid-gap: 20px;
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(2, 1fr);
}
.abtme {
	padding: 50px 0;
}
.abtme .myimg {
	align-items: center;
	background-color: var(--white);
	display: flex;
	justify-content: center;
}
.abtme .myimg img {
	border-radius: 50%;
	width: 190px;
}
.abtme .my_abt {
	background-color: var(--white);
}
.abtme .my_abt h2 {
	font-size: 36px;
	color: var(--black);
}
.abtme .my_abt p {
	font-size: 18px;
	font-weight: 600;
	margin-top: 26px;
	color: var(--black);
}
.abtme .exp {
	background-color: var(--white);
}
.abtme .exp .dskjsdk {
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
	align-items: baseline;
}
.abtme .exp h2 {
	color: var(--black);
}
.abtme .exp .dskjsdk h3 {
	display: flex;
	flex-direction: column;
	font-size: 20px;
	color: var(--black);
}
.abtme .exp .dskjsdk h4 {
	color: var(--grey);
	font-size: 17px;
}
.abtme .exp .dskjsdk h3 span {
	color: var(--grey);
	font-weight: 500;
	font-size: 17px;
}
.work_pg {
	gap: 40px;
	padding: 50px 0;
}
.work_pg .work_card {
	background-color: var(--white);
}
.work_pg .work_card img {
	border-radius: 15px;
}
.work_pg .work_card h2 {
	font-size: 32px;
	margin-bottom: 10px;
	margin-top: 40px;
	color: var(--black);
}
.work_pg .work_card p {
	font-size: 18px;
	font-weight: 600;
	margin-top: 20px;
	color: var(--black);
}
.work_pg .work_card .kls {
	align-items: center;
	display: flex;
	justify-content: space-evenly;
	justify-content: space-around;
	margin-top: 40px;
}
.work_pg .work_card .kls a {
	align-items: center;
	color: var(--s2);
	display: flex;
	font-size: 23px;
	font-weight: 700;
	gap: 20px;
}

.work_pg .work_card .kls a[aria-disabled='true'] {
	cursor: not-allowed !important;
	position: relative;
}
.work_pg .work_card .kls a[aria-disabled='true']::after {
	content: attr(area-message);
	position: absolute;
	top: -15px;
	left: 0;
	width: max-content;

	font-weight: 500;

	font-size: 16px;
	background-color: var(--s2);
	border-radius: 10px;
	color: var(--white);
	text-transform: uppercase;
	left: 50%;
	opacity: 0;
	padding: 7px 16px;
	position: absolute;
	transform: translateX(-55%) translateY(0);
	transition: all 0.3s;
}

.work_pg .work_card .kls a[aria-disabled='true']:hover:after {
	opacity: 1;
	top: -35px;
}

.work_pg .work_card .kls a img {
	border-radius: 0;
	transition: all 0.3s;
	width: 25px;
}
.work_pg .work_card .kls a a:hover img {
	width: 30px;
}

.error_page {
	height: calc(100vh - 80px);
	display: flex;
	align-items: center;
	justify-content: center;
}

/* form submited css */

.form_success {
	height: 80%;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 60px;
}

.form_success h3 {
	font-size: 18px;
	text-align: center;
	color: var(--black);
}

.form_success img {
	width: 80px;
	user-select: none;
	pointer-events: none;
}

/* form failed  */

.form_failed {
	height: 80%;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 60px;
}

.form_failed h3 {
	font-size: 18px;
	text-align: center;
}

.form_failed img {
	width: 80px;
	user-select: none;
	pointer-events: none;
}

.messeges {
	min-height: calc(100vh - 210px);
	padding: 20px;
	position: relative;
}

.messeges button img {
	width: 80%;
}
.messeges .flex-b-center {
	min-height: calc(100vh - 210px);
}
.messeges h1 {
	text-align: center;
	margin-bottom: 50px;
}
.messeges h1 span {
	font-size: 20px;
	vertical-align: super;
	color: var(--grey-2);
}
.messegecard {
	margin-top: 40px;
	padding: 30px 40px;
	box-shadow: #64646f33 0px 7px 29px 0px;
	position: relative;
}
.messegecard span {
	color: var(--grey-2);
	word-break: break-all;
	text-transform: lowercase;
}
.messegecard h3 {
	margin: 10px 0;
}

.messegecard button {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 30px;
	top: 50%;
	transform: translateY(-50%);
	width: 50px;
	height: 50px;
	padding: 10px;
	cursor: pointer;
	border-radius: 50%;
	border: none;
	box-shadow: #0366d64d 0px 0px 0px 3px;
	transition: all 0.3s;
}
.messegecard button:hover {
	background-color: var(--secondary-title);
}
.messegecard button:hover img {
	width: 75%;
}
.messegecard p {
	margin: 10px 0 0;
	font-weight: 500;
}

.messeges .refresh_btn {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 0;
	cursor: pointer;
	box-shadow: #64646f33 0px 7px 29px 0px;
	position: relative;
	top: 3px;
	margin-left: 20px;
}

.messeges .refresh_btn:hover {
	animation: spinner 1s ease-out;
}

.messeges .refresh_btn svg {
	width: 100%;
	width: 30px;
	height: 30px;
}

/* spinner css  */

.flex-b-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.spinner {
	width: 40px;
	height: 40px;
	border: 3px solid;
	border-left-color: var(--lightgray);
	border-top-color: var(--lightgray);
	border-right-color: var(--black);
	border-bottom-color: var(--black);
	animation: spinner 1s infinite linear;
	border-radius: 50%;
}

@keyframes spinner {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* contact form  */

.cform {
	position: fixed;
	top: 0;
	background-color: var(--overlay-bg);
	backdrop-filter: saturate(180%) blur(10px);
	z-index: 100;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.formwrapper {
	padding: 30px 40px;
	width: 70%;
	/* height: 70vh; */
	max-height: 550px;
	max-width: 800px;
	background-color: var(--white);
	position: relative;
	outline: 2px solid var(--outline);
}

.formwrapper .crossbtn {
	position: absolute;
	top: -19px;
	right: -13px;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	border: 0;
	background: var(--white);
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: center;
	outline: 2px solid var(--outline);
}

.formwrapper .crossbtn img {
	width: 50%;
	user-select: none;
	pointer-events: none;
}

.formwrapper h2 {
	font-size: 42px;
	font-weight: 700;
	text-align: center;
	color: var(--black);
}

.formwrapper form {
	margin-top: 50px;
}

.formwrapper form .flex {
	display: flex;
	align-items: center;
	gap: 20px;
	justify-content: space-between;
}

.formwrapper form input {
	width: 100%;
	padding: 12px 20px;
	border-radius: 5px;
	border: 1.7px solid #c1c1c1;
	font-size: 17px;
	transition: all 0.2s;
	background-color: transparent;
	color: var(--black);
}

.formwrapper form textarea {
	color: var(--black);
	width: 100%;
	padding: 13px 20px;
	border-radius: 5px;
	border: 1.7px solid #c1c1c1;
	font-size: 17px;
	margin-top: 20px;
	min-height: 200px;
	resize: none;
	display: inherit;
	background-color: transparent;
}

.formwrapper form button {
	width: 160px;
	padding: 12px 20px;
	border-radius: 10px;
	border: 1.7px solid #c1c1c1;
	font-size: 17px;
	margin-top: 20px;
	font-weight: 500;
	cursor: pointer;
	background: linear-gradient(317.33deg, var(--black), #bdbdbd 115.43%);
	color: var(--white);
	border: 0;
}

.formwrapper form button:disabled {
	cursor: not-allowed;
	background: none;
	color: #808080;
	outline: #808080 2px solid;
}

@media (max-width: 999px) {
	.cards_pskj,
	.grid2 {
		grid-template-columns: repeat(1, 1fr);
	}

	.abtme .my_abt p,
	.work_pg .work_card p {
		font-size: 16px;
		line-height: 22px;
		margin-top: 16px;
		font-weight: 500;
	}

	.skill h2 {
		font-weight: 700;
		color: var(--black);
	}

	.work_pg .work_card h2 {
		font-size: 28px;
		margin-top: 30px;
		color: var(--black);
	}

	.work_pg .work_card .kls a {
		font-size: 20px;
		gap: 10px;
	}

	.abt .resume,
	.pad {
		min-height: 250px;
		padding: 20px;
	}

	.work_pg .work_card img {
		border-radius: 8px;
	}

	nav .cnt_btn {
		border-radius: 5px;
	}
	.abtme .myimg img {
		width: 150px;
	}
	nav ul {
		background: var(--white);
		border-radius: 10px;
		box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, 0.2);
		flex-direction: column;
		gap: 15px;
		padding: 20px 0 30px;
		position: absolute;
		right: 0;
		top: 90%;
		transition: all 0.2s;
		width: 270px;
		z-index: 100;
	}
	nav ul li {
		text-align: center;
		width: 100%;
	}
	nav ul li a {
		margin: 0 auto;
		padding: 10px 0;
		width: 80%;
	}

	nav ul li a {
		display: block;
	}

	nav ul .mbl {
		display: flex;
	}

	nav ul.hidden {
		display: none;
		opacity: 0;
	}
	nav ul.visible {
		opacity: 1;
		visibility: visible;
	}
	.hnsm {
		display: none !important;
		visibility: hidden;
	}
	nav .menubtn {
		display: flex !important;
		transition: all 0.3s;
	}
	nav .menubtn:active {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
	.logo img {
		width: 110px;
	}
	.contact_card h3,
	.cortex .cntt h3 {
		font-size: 42px;
	}
	.cortex img {
		left: 50%;
		opacity: 0.7;
		position: absolute;
		top: 50%;
		-webkit-transform: translate(-50%, -75%);
		transform: translate(-50%, -75%);
		width: 150px;
		display: none;
	}
	footer .slogo {
		width: 35px;
	}
	footer .footerlinks li a {
		display: flex;
	}

	.formwrapper {
		max-height: none;
		height: auto;
		width: 80%;
	}

	.formwrapper form .flex {
		flex-direction: column;
	}

	.formwrapper h2 {
		font-size: 32px;
		color: var(--black);
	}

	.formwrapper form button {
		width: 100%;
	}

	.formwrapper form {
		margin-top: 30px;
	}

	.formwrapper form input {
		padding: 10px 20px;
		border-radius: 10px;
		font-size: 16px;
	}

	.formwrapper form textarea {
		border-radius: 10px;
		min-height: 160px;
		font-size: 16px;
	}

	.spinnerWrap {
		height: 200px;
	}

	.form_success {
		margin-top: 40px;
		gap: 30px;
	}

	.form_success img {
		width: 65px;
	}
}

@media (max-width: 650px) {
	.abt {
		grid-template-columns: repeat(1, 1fr);
	}
	.abt .flex-col,
	.abt .resume,
	.skill {
		min-height: 250px;
	}
	.card {
		text-align: center;
	}
	.skill .skills {
		gap: 30px;
	}

	.messegecard {
		padding: 20px 30px;
		padding-bottom: 100px;
	}

	.messegecard button {
		left: 50%;
		bottom: 25px;
		top: auto;
		transform: translateX(-50%);
	}

	.messegecard h3 {
		font-size: 16px;
	}

	.formwrapper {
		width: 90%;
		padding: 20px;
	}
}
@media (max-width: 480px) {
	.messegecard {
		padding: 20px 20px;
		padding-bottom: 100px;
	}
	.messegecard button {
		width: 40px;
		height: 40px;
	}
}

[data-animate='fade'] {
	animation: fade 0.2s;
}

@keyframes fade {
	0% {
		opacity: 0;
		transform: translateX(-10px);
	}

	60% {
		transform: translateX(0);
	}

	100% {
		opacity: 1;
	}
}

[data-animate='Workfade'] {
	animation: Workfade 0.2s;
}

@keyframes Workfade {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}

	60% {
		transform: translateX(0);
	}

	100% {
		opacity: 1;
	}
}

[data-animate='slideUp'] {
	animation: slideUp 0.2s;
}

@keyframes slideUp {
	0% {
		opacity: 0;
		transform: translateY(+20px);
	}

	60% {
		transform: translateX(0);
	}

	100% {
		opacity: 1;
	}
}

.resume-iframe {
	position: fixed;
	left: 10%;
	right: 10%;
	top: 10%;
	bottom: 10%;
	width: 80%;
	height: 80%;
	z-index: 100;
}
